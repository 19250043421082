<template>
  <div class="v-concepts" :class="parentSlug">
    <Breadcrumbs />
    <TheHeading class="v-concepts__title" level="h1">{{
      conceptsTitle
    }}</TheHeading>
    <div class="v-concepts__filtering">
      <div class="v-concepts__search">
        <Searchbox
          class="c-concepts-search__searchbox u-px--3"
          :class="[{ 'c-concepts-search__searchbox--has-phrase': term }]"
          :searchFormLabel="$t('SEARCH_FORM')"
          :searchPlaceholder="$t('SEARCH_PLACEHOLDER')"
          :searchButton="$t('SEARCH')"
          :characters="2"
          @submit-search="setPhrase"
        />
      </div>

      <div class="v-concepts__filter">
        <LanguageSelect
          v-if="languageList.length"
          :languageList="languageList"
          :currentLanguage="currentLanguage"
          :canClear="true"
          :canDeselect="true"
          :placeholder="$t('CONCEPTS_LANG_PLACEHOLDER')"
          :label="$t('CONCEPTS_LANG_LABEL')"
          @language-change="onLanguageChange"
        />
      </div>
    </div>

    <div class="v-concepts__wrapper">
      <Card
        v-for="concept in finalConceptsResults"
        class="v-concepts__concept"
        :key="concept.id"
        :title="concept.name"
        :description="concept.description"
        :image-id="concept.cover"
        :item="concept"
        :actions="cardActions"
      />
    </div>
    <div v-if="!loading && !finalConceptsResults.length">
      <TheHeading class="v-concepts__no-results">
        {{ $t('CONCEPTS_SEARCH_NO_RESULTS') }}
      </TheHeading>
    </div>
    <div v-if="loading && !finalConceptsResults.length">
      <TheHeading class="v-concepts__loading">
        {{ $t('CONCEPTS_SEARCH_LOADING') }}...
      </TheHeading>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import {
  getConceptsLanguage,
  setConceptsLanguage,
} from '@/services/languageSelect'
import useModal from '@/composables/useModal'
import useTranslations from '@/composables/useTranslations'
import {
  useStructures,
  useTenant,
  useBreadcrumbs,
  useLocalizations,
} from '@/core'
import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import Card from '@/components/Card'
import Searchbox from '@forlagshuset/v-searchbox'
import LanguageSelect from '@/components/LanguageSelect.vue'
import useStructuresLocal from '@/composables/useStructures'

const lunr = require('lunr')
require('lunr-languages/lunr.stemmer.support')(lunr)
require('lunr-languages/lunr.no')(lunr)

export default {
  name: 'ConceptsSearch',

  components: {
    Breadcrumbs,
    TheHeading,
    Card,
    Searchbox,
    LanguageSelect,
  },

  setup() {
    const loading = ref(true)
    const currentLanguage = ref('')
    const { locale } = useI18n()
    const lunrIdx = ref(null)
    const term = ref('')
    const {
      isFallbackLanguage,
      getTenantTranslation,
      gqlStructureChildrenQuery,
    } = useTranslations()
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const { getLocalizationById, fetchStructuresLocalization } =
      useLocalizations()
    const { tenant } = useTenant()
    const route = useRoute()
    const { setModal } = useModal()
    const parentSlug = computed(() => route.params.conceptsParent)
    const parentSlugPath = computed(() => {
      return (
        (tenant.value.concepts && tenant.value.concepts.slugPath) ||
        `${tenant.value.slug}/${parentSlug.value}`
      )
    })
    const {
      getStructuresBySlugPath,
      fetchStructuresChildren,
      fetchStructuresNode,
    } = useStructures()
    const { getStructuresChildrens } = useStructuresLocal()
    const parentStructure = computed(() =>
      getStructuresBySlugPath(
        parentSlugPath.value,
        true,
        'DIRECTORY',
        locale.value,
      ),
    )
    const languageList = computed(
      () =>
        (tenant.value.concepts &&
          tenant.value.concepts.langs?.map((lang) => ({
            value: lang.code,
            label: lang.label[locale.value],
          }))) ||
        [],
    )
    const onLanguageChange = async (lang) => {
      await setConceptsLanguage(lang)
      currentLanguage.value = lang
    }
    const conceptsTitle = computed(() => parentStructure.value.name)
    const concepts = computed(() => {
      return getStructuresChildrens(
        parentStructure.value.id,
        'DIRECTORY',
        locale.value,
      )
    })
    const finalConceptsResults = computed(() => {
      let result
      const conceptsData = concepts.value
      if (!lunrIdx.value) return []
      if (!term.value) return conceptsData.sort((a, b) => a.name.localeCompare(b.name, 'no'))
      result = lunrIdx.value
        .search(term.value + '*')
        .map(
          (obj) =>
            conceptsData.filter((concept) => obj.ref === concept.id)[0],
        )

      return result
    })
    const cardActions = {
      type: 'concept',
      action: (concept) => {
        onShowConcept(concept)
      },
    }
    const onShowConcept = (concept) => {
      setModal('ModalConcept', {
        title: concept.name,
        content: concept,
        type: 'concept',
        options: {
          translation: currentLanguage.value,
        },
      })
    }
    const setPhrase = (e) => {
      term.value = e
    }
    useHead({
      title: computed(
        () =>
          `${conceptsTitle.value} - ${getTenantTranslation(tenant.value.name)}`,
      ),
    })

    onMounted(async () => {
      clearBreadcrumbs()
      await fetchStructuresNode(parentSlugPath.value)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(
            parentStructure.value.id,
            locale.value,
          )
        }
      } catch {
        //
      }
      try {
        if (!isFallbackLanguage.value) {
          const limit = 200
          let hasItems = true
          let page = 0
          while (hasItems) {
            const res = await gqlStructureChildrenQuery(
              parentStructure.value.id,
              locale.value,
              limit,
              page,
            )
            const { total } = res.data.structure.data.children.pagination
            page++
            if (total <= page * limit) {
              hasItems = false
            }
          }
        } else {
          await fetchStructuresChildren(parentSlugPath.value, {
            limit: 2000,
            filter: `{"type": "DIRECTORY"}`,
          })
        }
      } catch {
        //
      }

      setBreadcrumbs([
        {
          name: conceptsTitle.value,
        },
      ])

      currentLanguage.value = (await getConceptsLanguage()) || ''

      lunrIdx.value = lunr(function () {
        this.use(lunr.no)
        this.pipeline.remove(lunr.stemmer)
        this.searchPipeline.remove(lunr.stemmer)
        this.pipeline.remove(lunr.stopWordFilter)
        this.searchPipeline.remove(lunr.stopWordFilter)
        this.pipeline.remove(lunr.no.stemmer)
        this.searchPipeline.remove(lunr.no.stemmer)
        this.pipeline.remove(lunr.no.stopWordFilter)
        this.searchPipeline.remove(lunr.no.stopWordFilter)
        this.ref('id')
        this.field('name')

        concepts.value.forEach((st) => {
          this.add(st)
        })
      })
      loading.value = false
    })

    return {
      loading,
      conceptsTitle,
      parentSlug,
      finalConceptsResults,
      onShowConcept,
      cardActions,
      setPhrase,
      term,
      getLocalizationById,
      locale,
      languageList,
      onLanguageChange,
      currentLanguage,
    }
  },
}
</script>
